/* eslint-disable react/no-danger */
import React, { useRef, useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { withPreview } from "gatsby-source-prismic"
import { useForm } from "react-hook-form/dist/index.ie11"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import styles from "../scss/acknowledgement.module.scss"
import useAmountTemplate from "../hooks/use-amount-template"

const fields =
  process.env.GATSBY_BUILD_LANG !== "fr"
    ? require("../data/acknowledgement.en.yml")
    : require("../data/acknowledgement.fr.yml")

const AcknowledgementPage = ({ data }) => {
  const { register, handleSubmit, errors } = useForm()
  const [amount, setAmount] = useState(useAmountTemplate("LEVEL"))
  const pageData = data.allPrismicAcknowledgement.nodes[0].data
  const buggedParagraph = useRef()

  // for some reason, this one div doesn't want to play nicely with react.
  // we have literally no idea why or how.
  // it's likely an edge case with the DOM and something being slightly
  // off from the spec.
  useEffect(() => {
    setAmount(useAmountTemplate("LEVEL"))
    const html = useAmountTemplate(pageData.first_paragraph.html)
    buggedParagraph.current.innerHTML = html
  }, [pageData.first_paragraph.html])

  return (
    <Layout colour="#f9f9f9" showHeader={false}>
      <SEO title={pageData.title.text} />
      <div className={`${styles.gridContainer} grid-container`}>
        <div className="grid-x grid-margin-x">
          <div className={`${styles.banner} cell`}>
            <Img
              fluid={pageData.header_banner.fluid}
              alt={pageData.header_banner.alt}
            />
          </div>
          <div className={`${styles.content} cell`}>
            <div className="grid-x grid-margin-x">
              <form
                className="cell"
                noValidate
                onSubmit={handleSubmit(() =>
                  navigate(`/apply?amount=${amount}`)
                )}
              >
                <div ref={buggedParagraph} />
                <div
                  key={amount}
                  className={`${styles.callout} callout`}
                  dangerouslySetInnerHTML={{ __html: pageData.callout.html }}
                />
                <fieldset>
                  <legend>
                    <h2 className={styles.acknowledgement_heading}>
                      {pageData.acknowledgement_heading.text}
                    </h2>
                  </legend>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.acknowledgement_text.html,
                    }}
                  />
                  <ul className={styles.checklist}>
                    {fields.map((field, i) => (
                      <li key={field.label} className={styles.chkLi}>
                        <label htmlFor={`field-${i}`}>
                          <input
                            type="checkbox"
                            name={`check[${i}]`}
                            id={`field-${i}`}
                            className={styles.chkCheckbox}
                            ref={register({ required: true })}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: useAmountTemplate(field.label),
                            }}
                          />
                          {/* {field.label} */}
                        </label>
                      </li>
                    ))}
                  </ul>
                </fieldset>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.acknowledgement_bottom_text.html,
                  }}
                />
                {
                  // search for non-undefined error
                  errors.check?.find(_ => _) && (
                    <div
                      className={`${styles.callout} callout`}
                      dangerouslySetInnerHTML={{
                        __html: pageData.error_alert_box.html,
                      }}
                    />
                  )
                }
                <input
                  type="submit"
                  className={`${styles.continue} button`}
                  value={pageData.submit_button_text.text}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicAcknowledgement {
      nodes {
        prismicId
        data {
          title {
            text
          }
          header_banner {
            fluid(maxWidth: 2000) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          first_paragraph {
            html
          }
          callout {
            html
          }
          acknowledgement_text {
            html
          }
          acknowledgement_heading {
            text
          }
          acknowledgement_bottom_text {
            html
          }
          error_alert_box {
            html
          }
          submit_button_text {
            text
          }
        }
      }
    }
  }
`

export default withPreview(AcknowledgementPage)
